import { htmlSpecialChars } from './htmlSpecialChars';
import { isInternalLink } from './isInternalLink';
import { isInpageLink } from './isInpageLink';
// import {UAParser} from 'ua-parser-js';
import anime from 'animejs';
/*
 *  ページ遷移アニメーション
 * --------------------------------------------------
 */
export var pageTransition = {
    // ページ遷移後のアクション
    pageIn: function (animeConfig, option) {
        var wrapper = document.querySelector(option.loaderWrapper);
        if (wrapper) {
            var svgImage_1 = wrapper.querySelector('svg');
            if (svgImage_1) {
                anime(animeConfig);
                addEventListener('load', function () {
                    anime
                        .timeline({
                        autoplay: true,
                        loop: false,
                        easing: 'easeInOutCirc'
                    })
                        .add({
                        targets: svgImage_1,
                        opacity: 0,
                        translateX: '-=100',
                        duration: 800,
                        begin: function () {
                            document.body.classList.add(option.grantClassToBody);
                        },
                        complete: function () {
                            wrapper.style.display = 'none';
                            anime.remove(animeConfig.targets);
                        }
                    });
                });
            }
        }
    },
    // ページ遷移前のアクション
    pageOut: function (option) {
        var anchors = document.querySelectorAll(option.anchors);
        anchors.forEach(function (el) {
            if (isInternalLink(el.href) === true && isInpageLink(el.href) === false) {
                el.addEventListener('click', function (event) {
                    event.preventDefault();
                    document.body.classList.add(option.grantClassToBody);
                    setTimeout(function () {
                        location = htmlSpecialChars(el.href);
                    }, 800);
                }, {
                    passive: false
                });
            }
        });
        // ブラウザバックした後、キャッシュを読んでたら発火(ios対策)
        onpageshow = function (event) {
            var classes = document.body.classList;
            if (event.persisted) {
                if (classes.contains(option.grantClassToBody)) {
                    classes.remove(option.grantClassToBody);
                }
            }
        };
    }
};
